import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import {store} from '@/store'
// @ts-ignore
import locale from 'view-ui-plus/dist/locale/en-US';
import ECharts from 'vue-echarts'
import { use } from "echarts/core"

// import ECharts modules manually to reduce bundle size
import {
    CanvasRenderer
} from 'echarts/renderers'
import {
    BarChart,
    PieChart
} from 'echarts/charts'
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components'

use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    PieChart
])

createApp(App)
    .use(store)
    .use(router)
    .use(ViewUIPlus, {locale})
    .component('v-chart', ECharts)
    .mount('#app')
