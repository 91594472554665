import {createRouter} from 'vue-router'
import {createWebHistory} from "vue-router/dist/vue-router";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue'),
        children: [
            {
                path: '/profile',
                name: 'Profile',
                component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
            },
            {
                path: '/summary',
                name: 'Summary',
                component: () => import(/* webpackChunkName: "summary" */ '@/views/assessment/Summary.vue'),
            },
            {
                path: '/assessment',
                name: 'Assessment',
                component: () => import(/* webpackChunkName: "assessment" */ '@/views/assessment/Assessment.vue'),
            },
            {
                path: '/review/:modelValue',
                name: 'Review',
                component: () => import(/* webpackChunkName: "review" */ '@/views/assessment/Review.vue'),
                props: true,
            },
            {
                path: '/report',
                name: 'Report',
                component: () => import(/* webpackChunkName: "report" */ '@/views/assessment/Report.vue'),
            },
            {
                path: '/report/details',
                name: 'Report Details',
                component: () => import(/* webpackChunkName: "details" */ '@/views/assessment/ReportDetails.vue'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from) => {
    if (to.name !== 'login' && !sessionStorage.getItem("login")) {
        return {name: 'login'};
    }
})

export default router
