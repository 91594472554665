import {createPinia} from "pinia"
import {createPersistedState} from 'pinia-plugin-persistedstate'


export const store = createPinia()
    .use(createPersistedState({
        serializer: {
            serialize: JSON.stringify,
            deserialize: JSON.parse,
        }
    }))

